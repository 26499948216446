import React from 'react'
import { Fragment } from 'react'
import FloatingContainer from '../../components/global/floatingContainer'
import MyTicketFields from './myticketFields'
import Introduction2 from '../../components/global/introduction2'
const MyTicket = () => {
    return (
        <Fragment>
            <Introduction2
                children={
                    <FloatingContainer
                        children={
                            <MyTicketFields />
                        }
                    />
                }
            />
        </Fragment>
    )
}

export default MyTicket