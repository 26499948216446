import React from 'react'
import './styles.scss'
import Accordion from 'react-bootstrap/Accordion';
import { Container } from 'react-bootstrap';


const StyledAccordion = ({ title, data }) => {
    return (
        <div className='AccordionWrapper'>
            <div className='AccordionTitleContainer'>
                <p>{title}</p>
            </div>

            <div className='AccoridonContainer'>
                <Container>

                    {/* <Accordion defaultActiveKey="0">
                        {
                            data?.map(d => (
                                <Accordion.Item eventKey={d.eventKey}>
                                    <Accordion.Header className='accordionHeader'>{d.header}</Accordion.Header>
                                    <Accordion.Body>
                                        {d.description}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                        }

                    </Accordion> */}

                    <div class="container py-5">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="main-timeline">

                                    {
                                        data?.map(d => (
                                            <div class="timeline">
                                                <div class="timeline-content">
                                                    <div class="circle">
                                                        <span class="homebox">
                                                            Q{d.id}
                                                        </span>
                                                    </div>
                                                    <div class="content">
                                                        <span class="year">{d.question}?</span>
                                                        <p class="description">
                                                            {d.answer}
                                                        </p>
                                                        <div class="icon"><span></span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                </Container >

            </div >
        </div >
    )
}

export default StyledAccordion