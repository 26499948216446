import React from 'react'
import './styles.scss'
import { services } from '../../../data'

const ServicesCards = () => {
    return (
        <div className='serviceWrapper'>
            <div className='serviceTitle' data-aos="fade-up" data-aos-duration="500">
                <p>Services</p>
            </div>
            <div className='serviceCardContainer'>
                {
                    services.map(service => (
                        <div className='serviceCard' data-aos="fade-up" data-aos-duration="1000">
                            <div className='serviceCardIconName'>
                                <div className='serviceCardIcon'>
                                    {service.largeIcon}
                                </div>
                                <h4 className='serviceCardName'>{service.name}</h4>
                            </div>
                            <div className='serviceCardDesription'>
                                <h6>
                                    {service.description}
                                </h6>
                            </div>

                        </div>
                    ))
                }
            </div>
        </div >
    )
}

export default ServicesCards