import React, { Fragment } from 'react'
import './styles.scss'
import styled from 'styled-components';
import backgroundVideo from '../../../assets/video/backgroundVideo.mp4'
import { Container } from 'react-bootstrap';

const Introduction = ({
    title1,
    mainTitle,
    title2
}) => {


    return (
        <Fragment>
            <div className='introductionWrapper'>
                <video
                    className='videoContainer'
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                    <source src={backgroundVideo} type="video/mp4" />
                </video>
                <Container className='introductionImageContainer'>
                    <div className='introductionFirstTitle' data-aos="fade-up" data-aos-duration="500">
                        <p>{title1}</p>
                    </div>
                    <div className='introductionMainTitle' data-aos="fade-up" data-aos-duration="1000">
                        <p>{mainTitle}</p>
                    </div>
                    <div className='introductionSecondTitle' data-aos="fade-up" data-aos-duration="1500">
                        <p>{title2}</p>
                    </div>
                </Container>

            </div>
        </Fragment>
    )
}

export default Introduction