import React from 'react'
import { Container } from 'react-bootstrap'
import './styles.scss'
import { testimonials } from '../../../data'
import Rating from '../rating'
import quote from '../../../assets/images/quote.svg';




const Testimonials = ({ title }) => {
    return (
        <div className='testimonialWrapper'>
            <div className='testimonialTitleContainer' data-aos="fade-up" data-aos-duration="500">
                <p>{title}</p>
            </div>
            <div class="grid-wrapper">

                {testimonials.map(testimonial => (
                    <div className={testimonial.size} data-aos="fade-up" data-aos-duration="1000">
                        <div className='testimonialAvatarName'>
                            <img src={testimonial.avatar} width={70} height={70} alt='testimonialAvatar' />
                            <div className='testimonialName'>
                                <h6>{testimonial.name}</h6>
                                <Rating rating={testimonial.rating} />

                            </div>
                        </div>
                        <div className='testimonialBodyContainer'>
                            <p>{testimonial.description}</p>
                        </div>
                    </div>
                ))
                }
            </div>
        </div>
    )
}

export default Testimonials