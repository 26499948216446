import React, { Fragment } from 'react'
import './styles.scss'
import { highlights } from '../../../data'

const ServiceHighlight = () => {
    return (
        <Fragment>

            <div className='serviceHighlightWrapper'>
                <div className='serviceHighlightMainTitle' data-aos="fade-up" data-aos-duration="500">
                    <p>HIGHLIGHTS</p>
                </div>

                <div className='serviceHighlightCardsContainer'>
                    {
                        highlights?.map(highlight => (
                            <div className='serviceHighlightCard' data-aos="fade-up" data-aos-duration="1000">
                                <div className='serviceHighlightTitle'>
                                    <p>{highlight?.title}</p>
                                </div>
                                <div className='serviceHighlightDescription'>
                                    <p>{highlight?.description}</p>
                                </div>
                            </div>
                        ))
                    }

                </div>

            </div>

        </Fragment>
    )
}

export default ServiceHighlight