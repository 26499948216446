import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./styles.scss";

// import required modules
import { Pagination, Autoplay } from "swiper";
import { Container } from "react-bootstrap";


const FloatingListSection = ({ data, margin }) => {
    return (
        <div className='floatingWrapper'>
            <Swiper
                loop={true}
                pagination={{
                    dynamicBullets: true,
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
                data-aos="fade-up" data-aos-duration="1500"
            >
                {
                    data?.map(data => (
                        <SwiperSlide className={`${data.className} floatingDataContainer`}>
                            <Container>
                                <div key={data.id}>
                                    <div className='floatingDataIcon'>
                                        {data.icon}
                                    </div>
                                    <div className='floatingTitleContainer'>
                                        <p>{data.title}</p>
                                    </div>
                                    <div className='floatingDescriptionContainer'>
                                        <p>{data.description}</p>
                                    </div>
                                </div>
                            </Container>
                        </SwiperSlide>

                    ))
                }
            </Swiper>
        </div>
    )
}

export default FloatingListSection