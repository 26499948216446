import React, { Fragment } from 'react'
import './styles.scss'
import styled from 'styled-components';
import backgroundVideo from '../../../assets/video/backgroundVideo.mp4'
import { Col, Container } from 'react-bootstrap';

const Introduction = ({
    children
}) => {


    return (
        <Fragment>
            <div className='introductionWrapper'>
                <video
                    className='videoContainer'
                    autoPlay
                    muted
                    loop
                    playsInline
                >
                    <source src={backgroundVideo} type="video/mp4" />
                </video>
                <Container className='introduction2Container'>
                    <div className='introduction2ChildrenContainer'>
                        {children}
                    </div>
                </Container>

            </div>
        </Fragment>
    )
}

export default Introduction