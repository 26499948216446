import React from 'react'
import './styles.scss'
import { Container } from 'react-bootstrap'
import { links, loggedInLinks } from '../../../data'
import { Link } from 'react-router-dom'
import logo2 from '../../../assets/images/fintekrecoverysmall.png'
import Cookies from 'universal-cookie'

const Footer = () => {
    const cookie = new Cookies();

    return (
        <div className='footerWrapper'>
            <Container className='footerBootstrapContainer'>
                <div className='footerContainer'>
                    <div className='footerLogoContainer'>
                        <img src={logo2} width={80} height={50} alt='footerLogo' />
                    </div>
                    <div className='footerLinksContainer'>
                        <div className='footerBody'>
                            {
                                links.map(link => (
                                    <Link to={link.path} className='footerLink'>
                                        <p>{link.link}</p>
                                    </Link>
                                ))
                            }
                            {
                                cookie.get('token') && loggedInLinks.map(link => (
                                    <Link to={link.path} className='footerLink'>
                                        <p>{link.link}</p>
                                    </Link>
                                ))
                            }
                        </div>

                    </div>
                    {/* <div className='footerEmailContainer'>
                        <div className='footerTitles'>
                            <p>GET IN TOUCH</p>
                        </div>
                        <div className='footerBody'>
                            <p>support@thecryptoclearance.com</p>
                        </div>
                    </div> */}
                    {/* <div className='footerAdressContainer'>
                        <div className='footerTitles'>
                            <p>ADRESS</p>
                        </div>
                        <div className='footerBody'>
                            <p>12345 Elm Street, Apartment 6789, Unit 101112,<br /> Building D, Tower 13, Central District, Downtown Area,<br /> Cityville, Stateville, United States, 54321-9876</p>
                        </div>
                    </div> */}
                </div>
            </Container>
            <div className='copyrightContainer'>
                <p>Copyright 2023 Crypto Recovery – All rights reserved</p>
            </div>
        </div>
    )
}

export default Footer