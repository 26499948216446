import {
    FaBitcoin,
    FaIdCard,
    FaUserLock,
    FaMask,
    FaExchangeAlt,
    FaShieldVirus
} from "react-icons/fa";
import { TfiReload } from 'react-icons/tfi'
import { HiMailOpen } from 'react-icons/hi'
import { MdOutlineSyncProblem, MdOutlineVisibilityOff, MdSecurity, MdWifiProtectedSetup } from "react-icons/md";
import { BsCoin, BsFillClipboardDataFill, BsCreditCardFill } from 'react-icons/bs';
import { AiOutlineWarning, AiOutlineCloudDownload, AiFillCustomerService } from 'react-icons/ai';
import { FiHardDrive } from 'react-icons/fi';
import { GiBreakingChain } from 'react-icons/gi';
import { FaUserShield } from 'react-icons/fa';
import { RiSecurePaymentFill } from 'react-icons/ri';

import avatar1 from './assets/images/avatar1.jpg'
import avatar2 from './assets/images/avatar2.jpg'
import avatar3 from './assets/images/avatar3.jpg'
import avatar4 from './assets/images/avatar4.jpg'
import avatar5 from './assets/images/avatar5.jpg'
import avatar6 from './assets/images/avatar6.jpg'
import avatar7 from './assets/images/avatar7.jpg'
import avatar8 from './assets/images/avatar8.jpg'
import avatar9 from './assets/images/avatar9.jpg'
import avatar10 from './assets/images/avatar10.jpg'
import avatar11 from './assets/images/avatar11.jpg'
import avatar12 from './assets/images/avatar12.jpg'
import users from './assets/images/users.png'
import headphones from './assets/images/headphones.png'
import speed from './assets/images/speed.png'
import handshake from './assets/images/handshake.png'
import doctor from './assets/images/doctor.png'


export const links = [
    {
        link: 'Home',
        path: '/',
    },
    {
        link: 'About Us',
        path: '/aboutus',
    },
    {
        link: 'Services',
        path: '/services',
    },
    {
        link: 'FAQ',
        path: '/faq',
    },
]

export const loggedInLinks = [
    {
        link: 'Profile',
        path: '/profile'
    },
    {
        link: 'My Ticket',
        path: '/ticket'
    },
    {
        link: 'Ticket Progress',
        path: '/ticket-progress'
    },
]

export const floatingData = [
    {
        id: '7',
        title: 'Data Privacy',
        description: 'Protect your personal and financial information from unauthorized access and data breaches. Safeguarding your data ensures that sensitive information remains confidential and is not misused or exploited by malicious individuals or organizations.',
        icon: <FaUserShield size={80} color="#c4feff" />,
    },
    {
        id: '8',
        title: '24/7 Monitoring',
        description: 'Benefit from continuous monitoring of your online accounts and transactions to detect any suspicious activities promptly. This proactive monitoring helps identify potential security threats or unauthorized access attempts, enabling timely action to prevent any financial losses or breaches of your online accounts.',
        icon: <AiFillCustomerService size={100} color="#c4feff" />,
    },
    {
        id: '9',
        title: 'Education and Awareness',
        description: 'Stay informed about the latest online scams, fraud techniques, and cybersecurity best practices to protect yourself from falling victim to them. Through educational resources and awareness programs, you can learn how to identify potential risks, recognize red flags, and implement effective security measures to safeguard your online presence and financial well-being.',
        icon: <BsFillClipboardDataFill size={80} color="#c4feff" />,
    },
    {
        id: '10',
        title: 'Legal Assistance',
        description: 'Access professional legal guidance and representation for cases related to financial fraud and cybercrime. In the event of any fraudulent activity or legal challenges, having expert legal support can help you navigate the complexities of the legal system, seek appropriate remedies, and protect your rights as a victim of financial fraud or cybercrime.',
        icon: <MdOutlineSyncProblem size={100} color="#c4feff" />,

    },
    {
        id: '11',
        title: 'Credit Monitoring',
        description: 'Monitor your credit reports and scores to ensure accuracy and promptly detect any unauthorized activities. By regularly reviewing your credit information, you can identify any discrepancies or suspicious transactions that could indicate identity theft or fraudulent use of your personal information.',
        icon: <BsCreditCardFill size={80} color="#c4feff" />,
    },
    {
        id: '12',
        title: 'Fraud Detection',
        description: 'Utilize advanced algorithms and machine learning techniques to identify and prevent fraudulent transactions. By leveraging cutting-edge technologies, fraud detection systems can analyze patterns, behaviors, and transaction data to flag and block potentially fraudulent activities, protecting your financial assets from unauthorized access and fraudulent transactions.',
        icon: <FiHardDrive size={80} color="#c4feff" />,
    },
    {
        id: '13',
        title: 'Secure Online Payments',
        description: 'Enjoy safe and secure online transactions with robust encryption and secure payment gateways. Secure online payment systems ensure that your sensitive financial information, such as credit card details or banking credentials, is encrypted during transmission, minimizing the risk of interception or unauthorized access by cybercriminals.',
        icon: <RiSecurePaymentFill size={80} color="#c4feff" />,
    },
    {
        id: '14',
        title: 'Cybersecurity Consulting',
        description: 'Receive expert advice and recommendations to enhance your online security infrastructure. Cybersecurity consultants can assess your current security measures, identify vulnerabilities, and provide customized solutions to strengthen your overall security posture. Their expertise helps you stay ahead of emerging threats and implement effective security strategies.',
        icon: <MdSecurity size={80} color="#c4feff" />,
    },
    {
        id: '15',
        title: 'Identity Theft Protection',
        description: 'Safeguard your identity from theft and fraudulent use through proactive monitoring and alert systems. Identity theft protection services offer continuous monitoring of your personal information, such as social security numbers, addresses, and financial records. They alert you to any suspicious activities or attempts to use your identity, allowing you to take immediate action to prevent further damage.',
        icon: <MdWifiProtectedSetup size={80} color="#c4feff" />,
    },
];

export const floatingData2 = [
    {
        id: '1',
        title: 'Seek Assistance',
        description: 'Engage a skilled Recovery Consultant to assess your situation.',
        icon: <img src={handshake} alt="handshake" />,
        className: 'lightTheme'
    },
    {
        id: '2',
        title: 'Construct Your Case',
        description: 'Gather the essential evidence and devise a winning strategy.',
        icon: <img src={doctor} alt="doctor" />,
        className: 'greenTheme'
    },
    {
        id: '3',
        title: 'Reclaim Your Assets',
        description: 'With the appropriate solution, let us retrieve your funds promptly!',
        icon: <FaBitcoin size={70} className='lightTheme' />,
        className: 'lightTheme'
    },

]

export const testimonials = [
    {
        id: 1,
        name: "John Smith",
        description: "Crypto Recovery helped me reclaim my lost funds quickly and efficiently. Highly recommended!",
        rating: "5",
        avatar: avatar1,
        size: "default"
    },
    {
        id: 2,
        name: "Sarah Johnson",
        description: "I'm extremely grateful to Crypto Recovery for recovering my inaccessible crypto assets. Great service!",
        rating: "5",
        avatar: avatar2,
        size: "default"
    },
    {
        id: 3,
        name: "Emily Roberts",
        description: "Crypto Recovery has been an absolute lifesaver for me. I cannot express enough gratitude for their expertise and dedication in helping me recover my lost crypto assets. When I first realized that my funds were inaccessible, I felt helpless and devastated. But thanks to Crypto Recovery's exceptional service, I regained control over my wealth and found hope again.",
        rating: "5",
        avatar: avatar3,
        size: "tall"
    },
    {
        id: 4,
        name: "Mark Davis",
        description: "From the moment I reached out to them, their team was incredibly responsive and understanding. They took the time to listen to my situation and provided me with a clear plan of action. Their knowledge and experience in the field of crypto asset recovery were evident throughout the entire process.",
        rating: "5",
        avatar: avatar4,
        size: "wide"
    },
    {
        id: 5,
        name: "Jennifer Wilson",
        description: "I had a great experience with Crypto Recovery. They were able to recover my funds with exceptional service.",
        rating: "5",
        avatar: avatar5,
        size: "default"
    },
    {
        id: 6,
        name: "Daniel Thompson",
        description: "One of the things that impressed me the most about Crypto Recovery was their commitment to keeping me informed and updated every step of the way. They provided regular progress reports and were always available to answer any questions or address any concerns I had. Their professionalism and communication skills are truly commendable.",
        rating: "5",
        avatar: avatar6,
        size: "tall"
    },
    {
        id: 7,
        name: "Jessica Adams",
        description: "I'm extremely satisfied with the services provided by Crypto Recovery. They recovered my funds with professionalism and speed.",
        rating: "5",
        avatar: avatar7,
        size: "default"
    },
    {
        id: 8,
        name: "Michael Martin",
        description: "Crypto Recovery is the real deal. They helped me recover my lost funds and provided excellent customer support throughout.",
        rating: "5",
        avatar: avatar8,
        size: "default"
    },
    {
        id: 9,
        name: "Sophie Brown",
        description: "I was amazed at how efficiently Crypto Recovery worked to recover my lost funds. They utilized cutting-edge tools and techniques, combining established blockchain analytics with their own investigative methodologies. It was clear that they left no stone unturned in their pursuit of recovering my assets.",
        rating: "5",
        avatar: avatar9,
        size: "wide"
    },
    {
        id: 10,
        name: "William Thompson",
        description: "Crypto Recovery exceeded my expectations. They were able to recover my funds when I thought it was impossible.",
        rating: "5",
        avatar: avatar10,
        size: "default"
    },
    {
        id: 11,
        name: "Olivia Martinez",
        description: "I highly recommend Crypto Recovery to anyone facing similar challenges. Whether you've lost access to your wallet, fallen victim to a scam, or encountered any other crypto-related issue, Crypto Recovery has the knowledge, expertise, and resources to assist you. They are a trusted partner in the crypto community, dedicated to helping individuals like me reclaim what is rightfully theirs.",
        rating: "5",
        avatar: avatar11,
        size: "tall"
    },
    {
        id: 12,
        name: "Robert Johnson",
        description: "Crypto Recovery is a trusted partner for crypto asset recovery. They provide top-notch service and expertise.",
        rating: "5",
        avatar: avatar12,
        size: "default"
    },
    {
        id: 13,
        name: "Emily Wilson",
        description: "I'm impressed by Crypto Recovery's professionalism and dedication to helping individuals recover their crypto assets.",
        rating: "5",
        avatar: avatar1,
        size: "default"
    },
    {
        id: 14,
        name: "Andrew Davis",
        description: "Not only did Crypto Recovery succeed in retrieving my crypto assets, but they also provided me with invaluable guidance on enhancing the security of my digital holdings. They shared insights and recommendations that have helped me protect my investments moving forward.",
        rating: "5",
        avatar: avatar2,
        size: "wide"
    },
    {
        id: 15,
        name: "Sophia Adams",
        description: "I highly recommend Crypto Recovery for their expertise and efficient recovery process. Great team!",
        rating: "5",
        avatar: avatar3,
        size: "default"
    },
    {
        id: 16,
        name: "Daniel Thompson",
        description: "I cannot thank Crypto Recovery enough for their exceptional service, professionalism, and unwavering commitment to helping individuals like me regain control over their crypto assets. They have truly exceeded my expectations, and I will forever be grateful for their assistance.",
        rating: "5",
        avatar: avatar4,
        size: "wide"
    },
    {
        id: 17,
        name: "Michael Wilson",
        description: "Crypto Recovery is a reliable and trustworthy partner for crypto asset recovery. I highly recommend their services.",
        rating: "5",
        avatar: avatar6,
        size: "default"
    },
    {
        id: 18,
        name: "Emily Brown",
        description: "I'm incredibly thankful to Crypto Recovery for their professionalism and dedication in recovering my crypto assets.",
        rating: "5",
        avatar: avatar5,
        size: "default"
    },
    {
        id: 19,
        name: "Jacob Adams",
        description: "Crypto Recovery has truly been a game-changer for me. Their expertise and effectiveness in recovering my crypto assets have left me speechless. I had lost all hope of ever regaining access to my funds, but thanks to Crypto Recovery, I am now back in control.",
        rating: "5",
        avatar: avatar8,
        size: "wide"
    },
    {
        id: 20,
        name: "Sophia Wilson",
        description: "Crypto Recovery provided excellent service and recovered my lost crypto funds. Highly recommended!",
        rating: "5",
        avatar: avatar7,
        size: "default"
    },
    {
        id: 21,
        name: "Benjamin Thompson",
        description: "What sets Crypto Recovery apart is their vast experience and deep understanding of the crypto landscape. They know the ins and outs of blockchain technology and have mastered the art of asset recovery. Their team of experts leverages advanced tools and techniques to navigate complex cases and deliver results.",
        rating: "5",
        avatar: avatar10,
        size: "wide"
    },
    {
        id: 22,
        name: "Olivia Martin",
        description: "I highly recommend Crypto Recovery for their exceptional service and commitment to recovering lost crypto assets.",
        rating: "5",
        avatar: avatar9,
        size: "default"
    },
    {
        id: 23,
        name: "David Thompson",
        description: "Crypto Recovery provided me with the peace of mind I needed by successfully recovering my lost crypto funds.",
        rating: "5",
        avatar: avatar12,
        size: "default"
    },
    {
        id: 24,
        name: "Ella Johnson",
        description: "I'm impressed by Crypto Recovery's professionalism and dedication to helping individuals recover their crypto assets.",
        rating: "5",
        avatar: avatar11,
        size: "default"
    },
    {
        id: 25,
        name: "Matthew Davis",
        description: "Crypto Recovery is the best in the business. They recovered my lost crypto assets with exceptional service.",
        rating: "5",
        avatar: avatar2,
        size: "default"
    },
    {
        id: 26,
        name: "Sophie Thompson",
        description: "I was particularly impressed by the level of personal attention and care I received from the Crypto Recovery team. They took the time to listen to my concerns and tailored their approach to my specific situation. Their dedication and empathy made the entire experience much more manageable.",
        rating: "5",
        avatar: avatar1,
        size: "tall"
    },
    {
        id: 27,
        name: "Adam Wilson",
        description: "Crypto Recovery provided me with excellent service and recovered my lost funds. Highly recommended!",
        rating: "5",
        avatar: avatar4,
        size: "default"
    },
    {
        id: 28,
        name: "Emma Johnson",
        description: "I'm extremely satisfied with Crypto Recovery's professionalism and expertise in recovering my crypto assets.",
        rating: "5",
        avatar: avatar3,
        size: "default"
    },
    {
        id: 29,
        name: "James Davis",
        description: "Recovering my crypto assets was not just a financial victory but also an emotional one. Crypto Recovery understood the significance of what I had lost and the impact it had on my life. They celebrated with me when the recovery was successful, and their genuine joy for my regained wealth was evident.",
        rating: "5",
        avatar: avatar6,
        size: "wide"
    }
]

export const services = [
    {
        name: 'Financial Deception',
        smallIcon: <FaIdCard size={30} color="#7e38b7" />,
        largeIcon: <FaIdCard size={80} color="#fff" />,
        description: 'Falling victim to fraudulent schemes that result in the loss of your digital assets.',
    },
    {
        name: 'Transaction Challenges',
        smallIcon: <TfiReload size={30} color="#7e38b7" />,
        largeIcon: <TfiReload size={80} color="#fff" />,
        description: 'Facing difficulties in transferring your cryptocurrencies due to technical issues or errors.',
    },
    {
        name: 'Coin Theft',
        smallIcon: <FaMask size={30} color="#7e38b7" />,
        largeIcon: <FaMask size={80} color="#fff" />,
        description: 'Experiencing the unauthorized appropriation of your coins by malicious individuals.',
    },
    {
        name: 'Online Harassment',
        smallIcon: <HiMailOpen size={30} color="#7e38b7" />,
        largeIcon: <HiMailOpen size={80} color="#fff" />,
        description: 'Being subjected to online harassment or blackmail related to your crypto holdings.',
    },
    {
        name: 'Password Loss',
        smallIcon: <FaUserLock size={30} color="#7e38b7" />,
        largeIcon: <FaUserLock size={80} color="#fff" />,
        description: 'Forgetting or misplacing the passwords or keys required to access your cryptocurrency.',
    },
    {
        name: 'Forked Coin Recovery',
        smallIcon: <BsCoin size={30} color="#7e38b7" />,
        largeIcon: <BsCoin size={80} color="#fff" />,
        description: 'Assisting you in recovering coins resulting from blockchain forks or airdrops.',
    },
    {
        name: 'Exchange Breach',
        smallIcon: <FaExchangeAlt size={30} color="#7e38b7" />,
        largeIcon: <FaExchangeAlt size={80} color="#fff" />,
        description: 'Recovering funds affected by security breaches or hacking incidents on cryptocurrency exchanges.',
    },
    {
        name: 'Ransomware Incidents',
        smallIcon: <AiOutlineWarning size={30} color="#7e38b7" />,
        largeIcon: <AiOutlineWarning size={80} color="#fff" />,
        description: 'Dealing with ransomware attacks where your crypto assets are held hostage.',
    },
    {
        name: 'Hard Drive Damage',
        smallIcon: <FiHardDrive size={30} color="#7e38b7" />,
        largeIcon: <FiHardDrive size={80} color="#fff" />,
        description: 'Recovering cryptocurrencies from damaged or inaccessible hard drives or storage devices.',
    },
    {
        name: 'Virus Impact',
        smallIcon: <FaShieldVirus size={30} color="#7e38b7" />,
        largeIcon: <FaShieldVirus size={80} color="#fff" />,
        description: 'Addressing the impact of viruses and ransomware on your cryptocurrency holdings.',
    },
    {
        name: 'Technical Issues',
        smallIcon: <MdOutlineSyncProblem size={30} color="#7e38b7" />,
        largeIcon: <MdOutlineSyncProblem size={80} color="#fff" />,
        description: ' Resolving issues arising from software glitches or compatibility problems affecting your crypto investments.',
    },
    {
        name: 'Data Breach Recovery',
        smallIcon: <BsFillClipboardDataFill size={30} color="#7e38b7" />,
        largeIcon: <BsFillClipboardDataFill size={80} color="#fff" />,
        description: 'Helping you recover funds compromised in data breaches or security compromises.',
    },
]

export const highlights = [
    {
        icon: <AiOutlineCloudDownload size={70} color="#0068FF" />,
        title: 'CRYPTO ASSET PROTECTION',
        description: 'Safeguard your valuable cryptocurrency assets with our comprehensive security solutions. We employ state-of-the-art encryption, multi-factor authentication, and cold storage techniques to ensure the utmost protection of your funds.'
    },
    {
        icon: <GiBreakingChain size={70} color="#0068FF" />,
        title: 'CRYPTO TRANSACTION ANALYSIS',
        description: 'Leverage our expertise in conducting in-depth analysis of cryptocurrency transactions. Our team utilizes advanced tools and techniques to trace and analyze blockchain transactions, identify patterns, and provide valuable insights for regulatory compliance and risk management.'
    },
    {
        icon: <MdOutlineVisibilityOff size={70} color="#0068FF" />,
        title: 'CRYPTO REGULATORY COMPLIANCE',
        description: 'Mitigate risks associated with cryptocurrency investments through our thorough due diligence services. We conduct comprehensive assessments of projects, teams, and tokenomics to provide you with valuable insights and help you make informed investment decisions.'
    },
];

export const moreData = [
    {
        id: '1',
        question: "FALL VICTIM TO CRYPTO TRADING FRAUD?",
        answer: 'According to a survey by securities regulators NASAA, crypto scams were the most prevalent form of cryptocurrency-based crime in 2021, resulting in billions of dollars lost. Scammers often promise guaranteed high returns with no risk, targeting unsuspecting investors. If you have been scammed and lost cryptocurrency, Crypto Recovery can help you recover your funds. We are a trusted crypto recovery service that has successfully assisted numerous scam victims from around the world in reclaiming their stolen funds.',
    },
    {
        id: '2',
        question: "BEWARE OF 'CRYPTO SERVICE' FRAUDS",
        answer: 'If you have fallen victim to fraud and lost your savings or retirement funds to a cryptocurrency scam, be cautious of offers promising to recover your lost funds. Recovery scams are a type of advance-fee fraud where victims are asked to pay upfront for the chance of receiving a much larger sum later. These scams target individuals who have already been harmed by other frauds. At Crypto Recovery, we are a trustworthy crypto recovery service that knows how to help you recover your funds. We have assisted hundreds of scam victims worldwide in recovering millions of dollars of stolen funds.',
    },
    {
        id: '3',
        question: "FORGOT OR MISPLACED YOUR PASSWORD?",
        answer: 'It is estimated that billions worth of Bitcoin have been locked by users who have forgotten or lost their access keys. Losing your private key means permanently losing access to your wallet and the funds it contains. Many people have been unable to cash in on their Bitcoin fortunes due to forgotten keys. If you have forgotten or misplaced your password, Crypto Recovery engineers can help.',
    },
    {
        id: '4',
        question: "ACCIDENTALLY DELETED YOUR WALLET?",
        answer: 'Accidental deletion of wallets is a common data loss scenario. In most cases, we can successfully recover deleted wallets without any data loss. At Crypto Recovery, we offer a "no data, no charge" guarantee, meaning you only pay if we successfully recover your deleted wallet. If you have accidentally deleted a wallet, it is important to turn off the affected device immediately to avoid permanent data loss.',
    },
    {
        id: '5',
        question: "HARDWARE, SOFTWARE ERRORS, AND VIRUSES",
        answer: 'Hardware and software errors, as well as viruses, can prevent cryptocurrency owners from accessing their coins, even with the correct password. Crypto Recovery specializes in restoring affected files and devices. Our experienced team uses state-of-the-art technology to recover cryptocurrency from failed hard drives, RAID arrays, flash drives, and other devices. If your computer or device is infected with viruses, we can help clear the virus without jeopardizing your cryptocurrency.',
    },
    {
        id: '6',
        question: "UPGRADING OLDER WALLET VERSIONS",
        answer: 'If you are experiencing trouble opening your wallet with the current version of wallet software or are hesitant to upgrade older wallets, Crypto Recovery can help. Our experts can resolve software compatibility issues and provide safe and secure upgrades to ensure you have an up-to-date wallet while maintaining your privacy.',
    },
    {
        id: '7',
        question: "SENT CRYPTOCURRENCY TO AN INVALID ADDRESS",
        answer: 'Accidentally sending cryptocurrency to the wrong address can be a stressful experience. If you have sent funds to an invalid address or the recipient claims they never received the funds, Crypto Recovery can assist you in recovering your cryptocurrency.',
    },
    {
        id: '8',
        question: "LOST FUNDS DUE TO FORKS",
        answer: 'Forks occur when there is a split in the transaction chain due to differing opinions among users about transaction history. If you have a bitcoin balance predating a fork and your funds were affected, Crypto Recovery can help. We work with leading vendors, experts, and employ proven blockchain analytics and investigative techniques to successfully recover your funds.',
    },
    {
        id: '9',
        question: "HACKED EXCHANGE ACCOUNT",
        answer: 'If your cryptocurrency funds have been stolen through a hacked exchange account, Crypto Recovery is here to assist you. We specialize in recovering funds lost due to hacking incidents and have a track record of successfully retrieving stolen assets. Contact us immediately to initiate the recovery process and reclaim your funds.',
    },
    {
        id: '10',
        question: "PHISHING ATTACK VICTIM",
        answer: 'Phishing attacks can lead to the unauthorized access of your cryptocurrency accounts, resulting in significant financial losses. If you have been a victim of a phishing attack and lost access to your funds, Crypto Recovery can help. Our team has extensive experience in dealing with phishing-related cases and can assist you in recovering your stolen funds.',
    },
    {
        id: '11',
        question: "LOST SEED PHRASE OR RECOVERY KEY",
        answer: 'The seed phrase or recovery key is crucial for accessing your cryptocurrency wallet. If you have lost or misplaced your seed phrase or recovery key, it can lead to the permanent loss of your funds. At Crypto Recovery, we specialize in helping individuals recover their lost seed phrases or recovery keys, enabling them to regain access to their wallets and retrieve their cryptocurrencies.',
    },
    {
        id: '12',
        question: "BITCOIN MINING MALWARE",
        answer: 'Bitcoin mining malware can infect your computer or device, utilizing its resources to mine cryptocurrency without your consent. If your device has been infected with mining malware, it can slow down your system and potentially compromise your funds. Crypto Recovery offers malware removal services to rid your device of mining malware and safeguard your cryptocurrencies.',
    },
    {
        id: '13',
        question: "COMPROMISED PRIVATE KEY",
        answer: 'The private key is the gateway to accessing and controlling your cryptocurrency funds. If your private key has been compromised, it puts your funds at risk of theft. Crypto Recovery specializes in assisting individuals who have experienced compromised private keys. Our experts employ advanced techniques to recover compromised private keys and restore the security of your funds.',
    },
    {
        id: '14',
        question: "CRYPTOCURRENCY EXCHANGE SHUTDOWN",
        answer: 'In the event of a cryptocurrency exchange shutdown, users may face difficulties accessing their funds or retrieving their cryptocurrencies. If you have been affected by a cryptocurrency exchange shutdown and are unable to access your funds, Crypto Recovery can assist you in recovering your assets. Our team has the expertise to navigate such situations and retrieve your funds securely.',
    },
    {
        id: '15',
        question: "ERROR IN TRANSACTION",
        answer: 'Mistakes can happen during cryptocurrency transactions, leading to funds being sent to the wrong address or lost in the blockchain. If you have made an error in a cryptocurrency transaction and need assistance in recovering your funds, Crypto Recovery is here to help. We have the necessary tools and expertise to trace and recover lost or misdirected transactions.',
    },
    {
        id: '16',
        question: "COMPROMISED WALLET SECURITY",
        answer: 'If your cryptocurrency wallet security has been compromised, it can put your funds at risk of unauthorized access and theft. At Crypto Recovery, we specialize in assisting individuals who have experienced compromised wallet security. Our team can help you recover your funds and implement enhanced security measures to protect your cryptocurrencies in the future.',
    },
    {
        id: '17',
        question: "LOST CRYPTOCURRENCY KEYS",
        answer: 'Losing your cryptocurrency keys can result in the permanent loss of your funds. If you have lost your cryptocurrency keys and need help in recovering them, Crypto Recovery can assist you. Our experts have the knowledge and tools to recover lost keys and restore access to your funds.',
    },
    {
        id: '18',
        question: "FAILED HARDWARE WALLET",
        answer: 'Hardware wallets provide secure storage for cryptocurrencies, but they can fail due to technical issues or physical damage. If your hardware wallet has failed, and you are unable to access your funds, Crypto Recovery can help. Our team specializes in recovering funds from failed hardware wallets and ensuring the security of your cryptocurrencies.',
    },
    {
        id: '19',
        question: "MULTIPLE WALLET SYNCHRONIZATION",
        answer: 'Synchronizing multiple wallets can be a complex process, and errors can occur, resulting in the loss of access to your funds. If you are facing issues with synchronizing multiple wallets and need assistance in recovering your funds, Crypto Recovery is here to help. Our team can guide you through the synchronization process and ensure the recovery of your cryptocurrencies.',
    },
    {
        id: '20',
        question: "VICTIM OF PONZI SCHEME",

        answer: 'Ponzi schemes targeting cryptocurrency investors can result in significant financial losses. If you have fallen victim to a Ponzi scheme and lost your funds, Crypto Recovery can assist you in recovering your assets. Our team has experience in dealing with Ponzi scheme cases and can help you navigate the recovery process effectively.',
    },
    {
        id: '21',
        question: "LOST CRYPTOCURRENCY INHERITANCE",
        answer: 'Inheriting cryptocurrency assets can be challenging if you do not have the necessary information or access to the funds. If you have lost cryptocurrency assets due to inheritance-related issues, Crypto Recovery can help you recover the lost assets. Our team understands the complexities of cryptocurrency inheritance and can guide you through the recovery process.',
    },
    {
        id: '22',
        question: "UNRECOVERABLE WALLET BACKUP",
        answer: 'Having a backup of your cryptocurrency wallet is crucial, but if the backup is lost or inaccessible, it can result in the permanent loss of your funds. If you are unable to recover your wallet backup and need assistance, Crypto Recovery can help. Our experts have the expertise to explore alternative recovery methods and retrieve your funds.',
    },
    {
        id: '23',
        question: "COMPROMISED EXCHANGE ACCOUNT",
        answer: 'Compromised cryptocurrency exchange accounts can lead to unauthorized access and loss of funds. If your exchange account has been compromised, it is important to act quickly. Crypto Recovery specializes in assisting individuals who have experienced compromised exchange accounts. Our team can help you recover your funds and enhance the security of your accounts.',
    },
];

export const serviceHighlight = [
    {
        id: '1',
        question: "Supported Currencies",
        answer: 'We can assist in the recovery of any type of wallet and cryptocurrency. We have expertise in recovering funds from various wallets and cryptocurrencies.Our comprehensive recovery services cover a wide range of wallets and cryptocurrencies. Whether you have lost Bitcoin, Ethereum, or any other digital asset, our skilled team possesses the expertise and resources required to assist you.'
    },
    {
        id: '2',
        question: "Success Rate and Possibility",
        answer: 'While we cannot provide guarantees, we carefully evaluate each case potential for recovery and offer honest assessments. Our priority is to work in the best interest of our clients, assessing the likelihood of successful recovery. While we cannot offer guarantees due to the unique nature of each case, we conduct a thorough evaluation of your situation. Our experienced team provides an honest assessment of the likelihood of successful recovery and guides you on whether it is advisable to proceed with our services.'
    },
    {
        id: '3',
        question: "Cost and Pricing",
        answer: 'Pricing is determined based on factors such as the value of your holdings, security complexities, legal considerations, and other relevant factors. We provide flexible pricing options, including contingency-based fees and competitive rates. Our pricing structure is tailored to the specific circumstances of your case. Factors such as the value of your lost holdings, the complexity of security issues, legal considerations, and other relevant complexities are taken into account. We offer personalized pricing options designed to provide transparency and flexibility. Our fees typically start at a competitive rate of 15% of the recovered amount.'
    },
    {
        id: '4',
        question: "Recovery Timeframe",
        answer: 'We strive to complete the recovery process efficiently, aiming to deliver results within a few weeks. Our goal is to expedite the recovery process while maintaining effectiveness. We understand the importance of a timely recovery process. While the timeframe can vary depending on the specific details of your case, our dedicated team is committed to delivering results as quickly as possible. Leveraging advanced techniques and resources, we aim to achieve tangible outcomes within the first few weeks, minimizing the waiting time for you.'
    },
    {
        id: '5',
        question: "Cryptocurrency Process",
        answer: 'Once the recovery is successful, we ensure the secure return of your cryptocurrency. Our team, in collaboration with trusted law firms, facilitates the complete return of your valuable investment. Upon successful recovery, we prioritize the secure return of your cryptocurrency. Our collaborative efforts with trusted law firms ensure a seamless process for the complete restoration of your valuable investment. Following the completion of the necessary investigations by our computer and investigation experts, the selected law firm facilitates the return of your funds, granting you full access to your assets and reinstating your control and ownership.'
    },

]   