import React, { useEffect, useState } from 'react'
import './styles.scss'
import { getTickets } from '../../api';
import Cookies from 'universal-cookie'
import moment from 'moment/moment';
import { Badge } from 'react-bootstrap';



const TicketsProgressFields = () => {
    const cookie = new Cookies()
    const id = cookie.get('id');
    const [ticket, setTicket] = useState([]);

    useEffect(
        () => {
            getTickets(id).then(async res => {
                setTicket(res.data.reverse())
            }).catch(error => console.log(error));
        },
        [])

    return (
        <div className='ticketProgressWrapper'>
            <div className='ticketProgressContainer'>
                <h4 className='ticketTracking mb-3'>Ticket Tracking</h4>

                {
                    ticket.map(item => (
                        <>
                            <div className='ticketProgress mb-3'>
                                <div className='ticketProgressSmallContainers'>
                                    <span className='ticketProgressSmallContainerTitle'>ID</span>
                                    <span>#{item?.generatedNumber}</span>
                                </div>
                                <div className='ticketProgressSmallContainers'>
                                    <span className='ticketProgressSmallContainerTitle'>Date</span>
                                    <span>{moment(item?.createdAt).format('L')}</span>
                                </div>
                                <div className='ticketProgressSmallContainers'>
                                    <span className='ticketProgressSmallContainerTitle'>Status</span>
                                    <span>{item?.status === 0 ? <Badge bg="warning" text="dark"> Warning</Badge> : <Badge bg="success">Completed</Badge>}</span>
                                </div>
                            </div>
                        </>
                    ))
                }
            </div>
        </div>
    )
}

export default TicketsProgressFields