import React from 'react'
import { Fragment } from 'react'
import FloatingContainer from '../../components/global/floatingContainer'
import TicketsProgressFields from './ticketprogressfields'
import Introduction2 from '../../components/global/introduction2'

const TicketProgress = () => {
    return (
        <Fragment>
            <Introduction2
                children={
                    <FloatingContainer
                        children={
                            <TicketsProgressFields />
                        }
                    />
                }
            />
        </Fragment>
    )
}

export default TicketProgress