import React, { Fragment } from 'react'
import Introduction from '../../components/global/introduction'
import { floatingData, floatingData2 } from '../../data'
import FloatingListSection from '../../components/global/floatingsection'
import AboutUsServices from '../../components/aboutus/services'
import './styles.scss'
import Testimonials from '../../components/global/testimonials'

const AboutUs = () => {
    return (
        <Fragment>

            <Introduction
                title1="Reclaim Your Lost Funds without any Upfront Costs."
                mainTitle="Recover Lost Funds: No Advance Payment Needed."
                title2="Regain access to your lost money and cryptocurrency without the need for upfront payment.Restore your lost funds and regain control of your money and cryptocurrency with no upfront payment required.Regain access to your lost money and cryptocurrency in a secure and risk-free manner without any initial payment necessary."
            />

            <AboutUsServices />

            <div style={{ marginTop: 32 }}>
                <p className='aboutusServiceTitle'>
                    SERVICES
                </p>
                <FloatingListSection
                    margin='64px 0px'
                    data={floatingData}
                />
            </div>

            <Introduction
                height={50}
                title1="Cryptocurrency Recovery Specialists"
                // mainTitle="Experts in Restoring Lost Cryptocurrency"
                title2="Specializing in recovering lost cryptocurrency funds, we are experts in helping you retrieve your assets with the highest success rate.With our specialized knowledge and experience, we excel in the recovery of lost cryptocurrency, offering you the best chance to regain your funds.Put your trust in our team of professionals who specialize in recovering lost cryptocurrency. We have the expertise to assist you in reclaiming your funds.As trusted experts in the field, we focus on recovering lost cryptocurrency funds, utilizing our skills and knowledge to increase the likelihood of successful recovery.Partner with our team of experts who are dedicated to restoring lost cryptocurrency. We have the skills and resources to maximize your chances of recovering your funds.With the rapid growth of the cryptocurrency market, it is not uncommon for individuals to encounter various challenges that can lead to the loss of their digital assets. Whether it be a forgotten password, a compromised wallet, a fraudulent transaction, or any other unfortunate circumstance, we are here to assist you in recovering your lost cryptocurrency.we pride ourselves on being experts in the field of cryptocurrency fund recovery. We understand that losing access to your valuable digital assets can be a distressing experience, and our primary goal is to help you regain control of your funds."
            />

            <Testimonials
                title='WE’VE HELPED MANY PEOPLE'
                subTitle='RECOVER THEIR LOST FUNDS'
            />
        </Fragment>
    )
}

export default AboutUs