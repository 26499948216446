import React, { Fragment } from 'react'
import FloatingContainer from '../../components/global/floatingContainer'
import ProfileFields from './profileFields'
import Introduction2 from '../../components/global/introduction2'

const Profile = () => {
    return (
        <Fragment>
            <Introduction2

                children={
                    <FloatingContainer
                        children={
                            <ProfileFields />
                        }
                    />
                }
            />

        </Fragment>
    )
}

export default Profile